<template>
  <!-- baner -->
  <div class="lemerce">
    <div class="lemerce_box">
      <div class="lemerce_top">
        <p>网红孵化</p>
        <p>INTERNET CELEBRITY</p>
      </div>
      <div class="lemerce_bottom">
        <div class="content_lsie">
          <div
            class="text_lefi"
            v-for="(item, index) in content"
            :key="index"
            @mouseover="active = index"
          >
            <p>{{ item.text }}</p>
            <i v-show="active == index" class="i"></i>
          </div>
          <div class="position">
            <p>vip</p>
          </div>
        </div>

        <div
          class="content_liexs"
          v-for="(item, index) in content"
          :key="index"
          v-show="active == index"
        >
          <div
            class="content_inedx"
            v-for="(itel, index) in item.cont"
            :key="index"
          >
            <img :src="itel.img" alt="" />
            <div class="text_opn">
              <p>{{ itel.text }}</p>
              <p>{{ itel.texti }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          text: "初级商学院",
          cont: [
            {
              img: require("@/assets/imgs/school/wh1.png"),
              text: "ELA老师",
              texti: "从事网红带货、引流5年，擅长网红的包装与推广",
            },
            {
              img: require("@/assets/imgs/school/wh1.png"),
              text: "ELA老师",
              texti: "从事网红带货、引流5年，擅长网红的包装与推广",
            },
            {
              img: require("@/assets/imgs/school/wh2.png"),
              text: "ELA老师",
              texti: "从事网红带货、引流5年，擅长网红的包装与推广",
            },
            {
              img: require("@/assets/imgs/school/wh3.png"),
              text: "ELA老师",
              texti: "从事网红带货、引流5年，擅长网红的包装与推广",
            },
          ],
        },
        {
          text: "进阶商学院",
          cont: [
            {
              img: require("@/assets/imgs/school/wh1.png"),
              text: "ELA老师",
              texti: "2从事网红带货、引流5年，擅长网红的包装与推广",
            },
            {
              img: require("@/assets/imgs/school/wh1.png"),
              text: "ELA老师",
              texti: "2从事网红带货、引流5年，擅长网红的包装与推广",
            },
            {
              img: require("@/assets/imgs/school/wh2.png"),
              text: "ELA老师",
              texti: "2从事网红带货、引流5年，擅长网红的包装与推广",
            },
            {
              img: require("@/assets/imgs/school/wh3.png"),
              text: "ELA老师",
              texti: "2从事网红带货、引流5年，擅长网红的包装与推广",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.lemerce {
  width: 100%;
  height: 1080px;
  margin-bottom: 160px;
  background: url("../../../assets/imgs/school/whfhbj.png") center no-repeat;
  overflow: hidden;
  .lemerce_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 211px;
    .lemerce_top {
      margin-bottom: 120px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      p:nth-child(2) {
        font-size: 19px;
        margin-top: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .lemerce_bottom {
          
      .content_lsie {
        max-width: 516px;
        height: 50px;
        position: relative;
        text-align: center;
        .text_lefi {
          width: 195px;
          float: left;
          cursor: pointer;
          p {
            font-size: 30px;
            font-family: DengXian;
            font-weight: 400;
            color: #ffffff;
            letter-spacing: 3px;
            line-height: 36px;
          }
          .i {
            display: inline-block;
            width: 160px;
            height: 4px;
            background: #224ede;
          }
        }
        .text_lefi:nth-child(2) {
          float: right;
        }
        .position {
          position: absolute;
          top: -5px;
          right: -35px;
          p {
            font-size: 25px;
            font-weight: bold;
            color: #e0b829;
          }
        }
      }
      .content_liexs {
        width: 100%;
        margin-top: 265px;
        .content_inedx {
          width: 276px;
          overflow: hidden;

          border-radius: 4px 4px 0px 0px;
          position: relative;
          cursor: pointer;
          > img {
            height: 262px;
          }
          .text_opn {
            width: 100%;
            height: 100%;
            border-radius: 4px 4px 0px 0px;
            position: absolute;
            top: 0px;
            left: 0px;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
            display: none;
            p:nth-child(1) {
              font-size: 30px;
              font-family: DengXian;
              font-weight: bold;
              color: #ffffff;
              line-height: 36px;
            }
            p:nth-child(2) {
              width: 195px;
              font-size: 18px;
              font-family: DengXian;
              font-weight: 400;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }
        .content_inedx:hover {
          top: -112px;
        }
        .content_inedx:hover img {
          height: 374px;
        }
        .content_inedx:hover .text_opn {
          display: block;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
        .content_inedx:nth-child(1) {
          float: left;
          margin-right: 32px;
        }
        .content_inedx:nth-child(2) {
          float: right;
        }
        .content_inedx:nth-child(3) {
          float: right;
          margin-right: 32px;
        }
      }
    }
  }
}
</style>
