<template>
  <!-- baner -->
  <div class="ecommerce">
    <div class="ecommerce_box">
      <div class="ecommerce_top">
        <p>电商孵化</p>
        <p>ONLINE RETAILERS</p>
      </div>
      <div class="ecommerce_bottom">
        <div
          class="position"
          v-for="(item, index) in content"
          :key="index"
          v-show="active == index"
        >
          <div class="content_left">
            <img :src="item.img" alt="" />
          </div>
          <div class="content_right">
            <div
              class="text_inedx"
              v-for="(itel, index) in item.cont"
              :key="index"
            >
              <p>{{ itel.text }}</p>
            </div>

            <div class="button">
              <img src="@/assets/imgs/school/right.png" alt="" />
              <img src="@/assets/imgs/school/left.png" alt="" />
            </div>
          </div>
        </div>

        <div class="nav_list">
          <div
            class="nav_inedx"
            v-for="(item, index) in content"
            :key="index"
            @mouseover="active = index"
          >
            <p>{{ item.text }}</p>
            <i class="i" v-show="active == index"></i>
          </div>
          <div class="position">
            <p>vip</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          text: "初级商学院",
          img: require("@/assets/imgs/school/dsfh.png"),
          cont: [
            {
              text: "第一期：教你如何私域锁客，流量池的秘密。",
            },
            {
              text: "第二期：教你如何私域锁客!",
            },
            {
              text: "第三期：打造转域能力，快速搭建企业私域流量！",
            },
            {
              text: "第四期：快速吸引客户进店！",
            },
            {
              text: "第五期：快速搭建企业私域流量!",
            },
          ],
        },
        {
          text: "进阶商学院",
          img: require("@/assets/imgs/school/dsfh.png"),
          cont: [
            {
              text: "第一期：2教你如何私域锁客，流量池的秘密。",
            },
            {
              text: "第二期：2教你如何私域锁客!",
            },
            {
              text: "第三期：2打造转域能力，快速搭建企业私域流量！",
            },
            {
              text: "第四期：2快速吸引客户进店！",
            },
            {
              text: "第五期：2快速搭建企业私域流量!",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.ecommerce {
  width: 100%;
  margin-bottom: 160px;
  overflow: hidden;
  .ecommerce_box {
    max-width: 1130px;
    margin: 0 auto;
    .ecommerce_top {
      margin-bottom: 98px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      p:nth-child(2) {
        font-size: 19px;
        margin-top: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
    .ecommerce_bottom {
      width: 100%;
      position: relative;

      .position {
        .content_left {
          float: left;
        }
        .content_right {
          width: 600px;
          float: right;
          overflow: hidden;
          margin-top: 157px;
          .text_inedx {
            border-bottom: 1px solid #1c1d1f;
            margin-bottom: 30px;
            p {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              margin-bottom: 30px;
            }
          }
          .text_inedx:nth-child(5) {
            border: 0;
          }
          .button {
            width: 113px;
            float: right;
            margin-right: 40px;
            margin-top: 40px;
            img {
              cursor: pointer;
            }
            img:nth-child(1) {
              float: left;
            }
            img:nth-child(1) {
              float: right;
            }
          }
        }
      }
      .nav_list {
        width: 516px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 84px;
        position: absolute;
        .nav_inedx {
          cursor: pointer;
          float: left;
          p {
            font-size: 36px;
            font-family: DengXian;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
          }
          .i {
            display: inline-block;
            width: 173px;
            height: 3px;
            background: #224ede;
          }
        }
        .nav_inedx:nth-child(2) {
          float: right;
        }
        .position {
          position: absolute;
          top: -10px;
          right: -47px;
          p {
            font-size: 25px;

            font-weight: bold;
            color: #e0b829;
          }
        }
      }
    }
  }
}
</style>
