
<template>
  <!-- baner -->
  <div class="college">
    <div v-for="(item, index) in content" :key="index" class="college">
      <a class="college_img"><img :src="item.img" alt="" /></a>
      <div class="position">
        <div class="slit">
          <p class="i"></p>
          <p>昆仑虚商学院</p>
          <p>教您进击电商运营大佬</p>
          <p>GET精准需求，让运营更轻松</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/school/baner.png"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.college,
.college_img,
.college_img > img {
  min-width: 1225px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  min-height: 755px;
}

.college_img {
  display: inline-block;
  position: relative;
}

.position {
  position: absolute;
  top: 260px;
  left: -190px;
  width: 100%;
  .slit {
    margin: 0 auto;
    width: 880px;
    height: 400px;
    .i {
      display: inline-block;
      width: 200px;
      height: 2px;
      border-top: 1px solid #fff;
      margin: 0px 0px 25px 11px;
    }
    p:nth-child(2) {
      font-size: 80px;
      font-family: Source Han Sans CN;
      letter-spacing: 2px;
      font-weight: 400;
      color: #ffffff;
    }
    p:nth-child(3) {
      width: 880px;
      margin-top: 60px;
      letter-spacing: 10px;
      font-size: 70px;
      line-height: 29px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    p:nth-child(4) {
      width: 630px;
      margin-top: 100px;
      letter-spacing: 5px;
      font-size: 30px;
      line-height: 29px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>


