
 <template>
  <div class="brand">
    <div class="brand_box">
      <div class="brand_top">
        <p>品牌孵化</p>
        <p>BRAND INCUBATION</p>
      </div>
      <div class="imgl">
        <img src="@/assets/imgs/school/pright.png" alt="" />
      </div>
      <div class="brand_botton">
        <div class="nav_lsit">
          <div
            class="nav_inedx"
            v-for="(item, index) in content"
            :key="index"
            @mouseover="active = index"
          >
            <p>{{ item.text }}</p>
            <i class="i" v-show="active == index"></i>
          </div>
          <div class="position">
            <p>vip</p>
          </div>
        </div>
        <div
          class="auto"
          v-for="(item, index) in content"
          :key="index"
          v-show="active == index"
        >
          <div class="content" v-for="(itel, index) in item.cont" :key="index">
            <div>
              <img :src="itel.img" alt="" />
            </div>
            <div class="text_p">
              <p>{{ itel.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="img"><img src="@/assets/imgs/school/pleft.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          text: "初级商学院",
          cont: [
            {
              img: require("@/assets/imgs/school/ppfh.png"),
              text: "第一期：打造转域能力，快速搭建企业私域流量！",
            },
            {
              img: require("@/assets/imgs/school/ppfh.png"),
              text: "第二期：教你如何私域锁客，流量",
            },
            {
              img: require("@/assets/imgs/school/ppfh1.png"),
              text: "第三期：教你如何私域锁客，流量池的秘密。",
            },
          ],
        },

        {
          text: "进阶商学院",
          cont: [
            {
              img: require("@/assets/imgs/school/ppfh.png"),
              text: "第一期：2打造转域能力，快速搭建企业私域流量！",
            },
            {
              img: require("@/assets/imgs/school/ppfh1.png"),
              text: "第二期：2教你如何私域锁客，流量",
            },
            {
              img: require("@/assets/imgs/school/ppfh.png"),
              text: "第三期：2教你如何私域锁客，流量池的秘密。",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.brand {
  width: 100%;
  margin-bottom: 100px;
  .brand_box {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    .brand_top {
      margin-bottom: 80px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      p:nth-child(2) {
        font-size: 19px;
        margin-top: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
    .brand_botton {
      max-width: 1200px;
      margin: 0 auto;
      .nav_lsit {
        width: 515px;
        margin: 0 auto;
        margin-bottom: 100px;
        text-align: center;
       cursor: pointer;
        .nav_inedx {
          float: left;
          p {
            font-size: 36px;
            font-family: DengXian;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
          }
          .i {
            display: inline-block;
            width: 173px;
            height: 3px;
            background: #224ede;
          }
        }
        .nav_inedx:nth-child(2) {
          float: right;
        }
        .position {
          position: relative;
          top: -9px;
          right: -285px;
          p {
            font-size: 25px;

            font-weight: bold;
            color: #e0b829;
          }
        }
      }
      .auto {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
      .content {
        margin: 0 auto;
        .text_p {
          width: 352px;
          margin-top: 30px;
          p {
            font-size: 23px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
          }
        }
      }
    }

    .img,
    .imgl {
      position: absolute;
      cursor: pointer;
    }
    .imgl {
      bottom: 165px;
      right: -40px;
    }
    .img {
      bottom: 165px;
      left: -40px;
    }
  }
}
</style>


