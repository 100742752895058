<template>
  <div>
    <College />
    <Lemerce/>
    <Ecommerce/>
    <Brand/>
  </div>
</template>
<script>
import College from "@/components/pages/college/College.vue";
import Lemerce from "@/components/pages/college/Lemerce.vue";
import Ecommerce from "@/components/pages/college/Ecommerce.vue";
import Brand from "@/components/pages/college/Brand.vue";

export default {
  components: {
    College,
    Lemerce,
    Ecommerce,
    Brand
  },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
